<template>

  <div class="post">
    <div>
      <h4>{{ post.title}}</h4>
      <router-link :to="{ name: 'EditPage', params: { id: post._id }}" :id="post._id">
        Изменить
      </router-link>
    </div>
    <div>
      <button @click="this.deletePost(post?._id)">Удалить запись</button>
    </div>

    <div class="button_group">
      <button @click="handleChangeOrder({ postId: post._id, direction: 'up' })">Поднять</button>
      <button @click="handleChangeOrder({ postId: post._id, direction: 'down' })">Опустить</button>
      <button @click="toggleField( post._id,  'isActive' )">
        Активен: {{ post.isActive ? 'Да' : 'Нет' }}
      </button>
      <button @click="toggleField(post._id, 'onMainPage' )">
        На главной: {{ post.onMainPage ? 'Да' : 'Нет' }}
      </button>
    </div>


  </div>


</template>

<script>
import AppService from "@/store/services";
import { mapActions } from 'vuex';

export default {
  props: {
    post: {
      type: Object,
      required: true,
    },
  },
  methods: {
    ...mapActions(['changeOrder',]),
    async deletePost(id) {
      await AppService.deletePost(id);
      this.$emit('postUpdated');
    },
    async handleChangeOrder({postId, direction}) {
      await this.changeOrder({postId, direction});
    },
    async toggleField(postId, fieldName) {
      await AppService.toggleFieldValue(postId, fieldName).then(() => {
        this.$emit('postUpdated');
      })
    }
  }
};

</script>

<style scoped>
.post {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  gap: 20px;
  padding: 20px;
  width: 100%;
  background: rgba(240,240,240, 40%);
  border-radius: 10px ;
  height: 150px;
  transition: .2s ease;

  &:nth-child(2n) {
    background: rgba(200,240,200, 40%);

    &:hover {
      background: rgba(200,240,200, 80%);
    }
  }

  &>div {
    display: flex;
    justify-content: space-between;
  }

  &:hover {
    background: rgba(240,240,240, 100%);
  }

  a:hover {
    text-decoration: underline;
  }
}
p {
  justify-self: flex-start;
  align-self: flex-start;
}

h4 {
  font-size: 16px;
}

img {
  width: 100%;
  max-width: 500px;
  height: auto;
}

.button_group {
  display: flex;
  width: 50%;
  justify-content: space-around;
}

.button_group button {
  padding: 5px;
  font-size: 12px;
  cursor: pointer;
  border-radius: 10px ;
  transition: .2s ease;


  &:hover {
    background: rgba(240,240,240, 100%);
  }
}
</style>
