<template>
  <div class="page container" v-if="post">
    <h6>
      {{ post.title }}
    </h6>
    <h3>
      {{ post.subtitle }}
    </h3>

    <template v-for="block in post.blocks">
      <div v-if="block.content_blocks.length === 2" class="double">
        <template v-for="cb in block.content_blocks">
          <div v-if="cb.type === 'image'">
            <img :src="cb.data[0]" alt="">
          </div>
          <div v-if="cb.type === 'video'">
            <video :src="cb.data[0]" autoplay muted loop playsinline>
            </video>
          </div>
          <div v-if="cb.type === 'slider'">
            <swiper
                :pagination="true"
                :modules="modules"
                :slides-per-view="1"
            >
              <swiper-slide v-for="data in cb.data">
                <img :src="data" alt="">
              </swiper-slide>
            </swiper>
          </div>
        </template>
      </div>
      <template v-if="block.content_blocks.length === 1">
        <p v-if="block.content_blocks[0].type === 'text'">
          {{ block.content_blocks[0].data[0] }}
        </p>
        <div v-if="block.content_blocks[0].type === 'image'" :class="block.content_blocks[0].size">
          <img :src="block.content_blocks[0].data[0]" alt="">
        </div>
        <div v-if="block.content_blocks[0].type === 'video'" :class="block.content_blocks[0].size">
          <video :src="block.content_blocks[0].data[0]" autoplay muted loop>
          </video>
        </div>
        <div v-if="block.content_blocks[0].type === 'slider'" :class="block.content_blocks[0].size">
          <swiper
              :pagination="true"
              :modules="modules"
          >
            <swiper-slide v-for="(data, i) in block.content_blocks[0].data" :key="i">
              <img :src="data" alt="">
            </swiper-slide>
          </swiper>
        </div>
      </template>
    </template>

    <div class="relevants">
      <h6>relevant cases</h6>
      <swiper
          :pagination="true"
          :modules="modules"
          :slides-per-view="3"
          :space-between="16"
      >
        <swiper-slide v-for="(article, i) in articles" :key="i">
          <router-link :to="{ name: 'Work', params: { id: article._id }}" :id="article._id">
            <div class="img">
              <img :src="article.main_img" alt="">
            </div>
            <h5>{{ article.title }}</h5>
          </router-link>
        </swiper-slide>
      </swiper>
    </div>
  </div>
</template>

<script>
import AppService from "@/store/services";
import {Swiper, SwiperSlide} from 'swiper/vue';
import {Navigation, Pagination} from 'swiper/modules';
import 'swiper/css';
import 'swiper/css/navigation';
import 'swiper/css/pagination';
import {mapActions, mapGetters} from "vuex";

export default {
  components: {
    Swiper,
    SwiperSlide
  },
  data() {
    return {
      post: null,
      articles: [],
    };
  },
  props: {
    id: {
      type: String,
      required: true
    }
  },
  computed: {
    ...mapGetters(['getPosts']),
    posts() {
      return this.getPosts?.posts;
    },
  },
  async created() {
    await this.getPost();
    this.getSliderPosts();
  },

  methods: {
    async getPost() {
      try {
        this.post = await AppService.getPostById(this.id);
      } catch (error) {
        console.error(error);
      }
    },
    ...mapActions(['fetchPosts']),
    getSliderPosts() {
      this.fetchPosts().then(() => {
        this.posts = this.posts.reverse(); // Используйте 'data' вместо 'fetched'
        this.articles = [];
        for (let i = 0; (i < 9) && (i < this.posts.length); i++) {
          if (this.posts[i]?._id != this.id) {
            this.articles.push(this.posts[i])
          }
        }
      });
    }
  },
  watch: {
    id() {
      this.getPost();
      this.getSliderPosts();
    }
  },

  setup() {
    return {
      modules: [Navigation, Pagination],
    };
  },
}
</script>

<style scoped lang="scss">
h6, h3, p {
  text-align: center;
  max-width: 1000px;
}

p {
  font-size: 24px;
  margin: 30px 0;
}

.page {
  padding-top: 200px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  align-items: center;
  min-height: 100vh;
}

img, video {
  width: 100%;
  height: 100%;
  object-fit: cover;
}

.s, .m, .l {

}

.s {
  width: 760px;
  height: 428px;
}

.m {
  width: 900px;
  height: 506px;
}

.l {
  width: 762px;
  height: 950px;
}

.xl {
  width: 760px;
  height: 428px;
}

.double {
  width: 1000px;
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 10px;

  & > div {
    height: 634px;
    width: 100%;
    max-width: 100%;
  }

  .swiper {
    width: 495px;
  }
}

@media screen and (max-width: 999px) {
  .double {
    width: 100%;
    display: flex;
    flex-direction: column;

    .swiper {
      width: 100%;
    }

    & > div {
      height: 560px;
      width: 100%;
    }
  }

  .l {
    width: 650px;
    height: 812px;
  }

  .m {
    width: 750px;
    height: 421px;
  }

  .s {
    width: 650px;
    height: 366px;
  }
}

@media screen and (max-width: 809px) {
  .double {
    grid-template-columns: repeat(1, 1fr);

    & > div {
      height: 450px;
    }
  }

  p {
    font-size: 18px;
  }

  .l {
    width: 320px;
    height: 399px;
  }

  .m {
    width: 300px;
    height: 168px;
  }

  .s {
    width: 320px;
    height: 180px;
  }
}

.relevants {
  margin-top: 80px;
  width: 100%;
  max-width: 1000px;
  padding: 0 20px;

  h6 {
    margin-bottom: 30px;
  }

  .img {
    height: 200px;
    overflow: hidden;

    img {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  h5 {
    font-size: 16px;
    margin-top: 10px;
  }
}

</style>

<style>
.swiper {
  width: 100%;
  height: 100%;
}

.swiper-button-next::after, .swiper-button-prev::after {
  color: black;
  font-size: 30px;
}

.swiper-button-prev, .swiper-button-next {
  width: 30px;
}

.swiper-horizontal>.swiper-pagination-bullets, .swiper-pagination-bullets.swiper-pagination-horizontal, .swiper-pagination-custom, .swiper-pagination-fraction {
  left: 50%;
  width: auto;
  transform: translateX(-50%);
  background-color: rgba(0, 0, 0, 0.04);
  backdrop-filter: blur(4px);
  border-radius: 20px;
}

.swiper-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet, .swiper-pagination-horizontal.swiper-pagination-bullets .swiper-pagination-bullet {
  background: #fff;
  opacity: .5;
}

.swiper-horizontal>.swiper-pagination-bullets .swiper-pagination-bullet-active {
  background: #fff;
  opacity: 1;
}
</style>