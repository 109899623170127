<script>

</script>

<template>
  <div class="page container">
    <h6>CONTACT</h6>
    <div class="contact">
      <h3>Georgia, Tbilisi,</h3>
      <h3>Aleksandre Ioseliani Street 52b</h3>
      <h3>+995522221007</h3>
      <h3><a href="mailto:hi@oneonone.team">hi@oneonone.team</a></h3>
    </div>
    <div class="contact">
      <h3>UAE, Dubai, </h3>
      <h3>Palm Jumeirah, Sunrise Bay Tower 2</h3>
      <h3>+971569504947‬</h3>
      <h3><a href="mailto:hi@oneonone.team">hi@oneonone.team</a></h3>
    </div>
  </div>
</template>

<style scoped lang="scss">
.page {
  padding-top: 200px;
  text-align: center;

  a {
    text-decoration: underline;
  }

  h6 {
    margin-bottom: 18px;
  }

  .contact {
    margin-bottom: 60px;
  }
}

@media screen and (max-width: 809px) {
  .page {
    padding-top: 170px;
  }
}
</style>