<template>
  <Header></Header>
  <router-view v-slot="{Component}">
    <transition name="page-opacity" mode="out-in">
      <component :is="Component"/>
    </transition>
  </router-view>
  <Footer></Footer>
</template>

<script>
import Header from "@/components/Header.vue";
import Footer from "@/components/Footer.vue";

export default {
  components: {
    Header,
    Footer
  }
}
</script>

<style lang="scss">
@font-face {
  font-family: 'Inter';
  font-weight: 400;
  src: url("@/assets/Fonts/InterTight-Regular.ttf") format('truetype');
}
@font-face {
  font-family: 'Inter';
  font-weight: 500;
  src: url("@/assets/Fonts/InterTight-Medium.ttf") format('truetype');
}
@font-face {
  font-family: 'Inter';
  font-weight: 600;
  src: url("@/assets/Fonts/InterTight-SemiBold.ttf") format('truetype');
}

#app {
  font-family: 'Inter', Helvetica, Arial, sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  width: 100%;
  min-height: 100svh;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  align-items: center;
  color: #171717;
  font-size: 16px;
  line-height: 1.2;
}

textarea {
  font-family: 'Inter', Arial, sans-serif;
}

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;

  //outline: 2px solid;
}

.page {
  flex-grow: 1;
  padding-top: 80px
}

.container {
  max-width: 1300px;
  padding-left:  10px;
  padding-right:  10px;
  width: 100%;
  margin: 0 auto;
}

h1 {

}

h2 {

}

h3 {
  font-size: 36px;
  line-height: 1.3;
  font-weight: 400;
}

h4 {

}

h5 {
  font-size: 24px;
  font-weight: 400;
}

h6 {
  color: #a1a1a1;
  font-size: .75em;
  font-weight: 400;
  letter-spacing: 2.4px;
  text-transform: uppercase;
}

a {
  text-decoration: none;
  color: inherit;
}



.page-opacity-enter-active, .page-opacity-leave-from, .page-opacity-leave-active {
  transition: .1s linear all;
}

.page-opacity-enter-from, .page-opacity-leave-to, .page-opacity-enter {
  opacity: 0;
}


@media screen and (max-width: 1399px) {
  .container {
    padding-left: 80px;
    padding-right: 80px;
  }
}

@media screen and (max-width: 999px) {
  .container {
    padding-left: 32px;
    padding-right: 32px;
  }
}

@media screen and (max-width: 809px) {
  .container {
    padding-left: 12px;
    padding-right: 12px;
  }

  body {
    -webkit-text-size-adjust: 100%;
  }

  h3 {
    font-size: 24px;
  }
}



@media screen and (max-width: 1399px) {

}

@media screen and (max-width: 999px) {

}

@media screen and (max-width: 809px) {

}
</style>
