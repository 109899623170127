<script>
export default {
  data() {
    return  {
      isOpen: false,
    }
  },
  watch: {
    $route (to,from) {
      this.isOpen = false
    }
  },
  methods: {
    mobileButtonClick() {
      this.isOpen = !this.isOpen
    }
  }
}
</script>

<template>
  <header class="header" :class="{ open: isOpen }">
    <div class="header-top">
      <router-link to="/" class="header-logo">
        Oneonone
      </router-link>

      <div class="header-nav">
        <router-link to="/about">About</router-link>
        <router-link to="/works">Works</router-link>
        <router-link to="/Jobs">Jobs</router-link>
      </div>

      <div class="header-contact">
        <router-link to="/contacts" class="contacts">
          Contact
        </router-link>
        <div class="menu-b" @click="mobileButtonClick()">
          <div class="burger"></div>
        </div>
      </div>
    </div>

    <div class="header-mobile">
      <div class="header-nav-m">
        <router-link to="/about">About</router-link>
        <router-link to="/works">Works</router-link>
        <router-link to="/Jobs">Jobs</router-link>
        <router-link to="/contacts">
          Contact
        </router-link>
      </div>
    </div>
  </header>
</template>

<style scoped lang="scss">
.header {
  width: 780px;
  border-radius: 100px;
  border: 1px solid #eee;
  padding: 5px 7px;
  line-height: 1.5;
  background: #fff;

  position: fixed;
  z-index: 100;
  top: 30px;
  left: 50%;
  transform: translateX(-50%);

  &-top {
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: center;
  }

  &-logo {
    padding: 6px 12px 5px;
    font-weight: 500;
    flex-grow: .2;
  }

  &-nav {
    flex-grow: 1;
    display: flex;
    flex-direction: row;
    justify-content: center;
    align-items: center;
    gap: 12px;

    &-m {
      display: none;
    }

    a {
      font-size: 14px;
      color: #171717;
      opacity: .6;
      text-transform: capitalize;
      transition: .15s ease;
      &:hover {
        opacity: 1;
      }
    }
  }

  &-contact {
    flex-grow: .2;
    display: flex;
    justify-content: flex-end;
    font-size: 14px;
    font-weight: 500;
    color: #fff;

    a {
      background: #171717;
      border-radius: 40px;
      padding: 6px 10px;
      transition: .15s ease;

      &:hover {
        opacity: .9;
      }
    }
  }

  &-mobile {
    display: none;
  }

  .menu-b {
    display: none;
  }
}

@media screen and (max-width: 999px) {

}

@media screen and (max-width: 809px) {
  .header {
    width: 346px;
    border-radius: 24px;
    max-height: 48px;
    overflow: hidden;
    transition: .4s ease-in-out;

    &-mobile {
      display: block;
      padding: 20px 14px 16px;
    }

    &-nav {
      display: none;

      &-m {
        display: flex;
        flex-direction: column;
        gap: 24px;

        a {
          color: rgb(161, 161, 161);
          font-size: 14px;
        }
      }
    }

    .contacts {
      display: none;
    }

    .menu-b {
      display: block;
      width: 32px;
      height: 32px;
      border-radius: 50%;
      background: black;
      border: 1px solid black;
      transition: .2s ease;
      padding-top: 13px;

      .burger {
        width: 14px;
        height: 6px;
        position: relative;
        transition: .2s ease;
        margin: 0 auto;

        &::before {
          content: '';
          width: 100%;
          height: 1px;
          background: #fff;
          position: absolute;
          left: 0;
          top: 0;
          transition: .3s ease;
        }

        &::after {
          content: '';
          width: 100%;
          height: 1px;
          background: #fff;
          position: absolute;
          left: 0;
          bottom: 0;
          transition: .3s ease;
        }
      }
    }
  }

  .open {
    background: #171717;
    color: #fff;
    border-color: #000;
    max-height: 350px;

    .menu-b {
      background: #fff;

    }

    .menu-b .burger {
      &::after, &::before {
        background: #000;
      }

      &::before {
        transform: rotate(45deg) translateX(1.5px) translateY(1.5px);
      }

      &::after {
        transform: rotate(-45deg) translateX(1.5px) translateY(-1.5px);
      }
    }
  }
}
</style>