<template>
  <div class="login__wrapper">
    <h1>Вход</h1>
    <form @submit.prevent="login">
      <div class="form-group">
        <label for="login">Логин</label>
        <input type="text" id="login" v-model="formData.email" placeholder="Введите логин" />
      </div>
      <div class="form-group">
        <label for="password">Пароль</label>
        <input type="password" id="password" v-model="formData.password" placeholder="Введите пароль" />
      </div>
      <button :disabled="isLoading" type="submit" class="btn btn-primary">Войти</button>
    </form>
  </div>
</template>

<script>
import AppService from "@/store/services";

export default {
  data() {
    return {
      formData: {
        email: '',
        password: '',
      },
      isLoading: false,
    };
  },
  methods: {
    async login() {
      this.isLoading = true;
      try {
        const responseData = await AppService.postLogin(this.formData.email, this.formData.password);
        console.log(responseData.isAdmin);
        if (responseData.isAdmin === true) {
          this.setAuthStatus(true);
          this.setUserInfo(responseData);
          this.$router.push('/admin');
        } else {
          this.setAuthStatus(false);
          this.setUserInfo(null);
        }
        this.isLoading = false;
      } catch (error) {
        console.error(error);
        this.setAuthStatus(false);
        this.setUserInfo(null);
        this.isLoading = false;
      }
    },
    setAuthStatus(isAuthenticated) {
      this.$store.commit("setAuthStatus", isAuthenticated);
    },
    setUserInfo(userInfo) {
      this.$store.commit("setUserInfo", userInfo);
    }
  }
};
</script>

<style lang="scss" scoped>
.login__wrapper {
  padding-top: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 40px;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 5px;
  margin-bottom: 20px;

  input {
    font-size: 18px;
  }
}

button {
  background: none;
  border: none;
  outline: none;
  font-size: 22px;
  cursor: pointer;
  position: relative;

  &::after {
    content: '';
    width: 100%;
    height: 1px;
    background: #000;
    opacity: 0;
    transition: .2s ease;
    position: absolute;
    bottom: 0;
    left: 0;
  }

  &:hover::after {
    opacity: 1;
  }
}
</style>
