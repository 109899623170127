<template>
  <div class="admin container">
    <div class="admin__nav nav">
      <p class="nav__item">Работы</p>
    </div>
    <div class="blogitems">
      <h2>Работы</h2>
      <div class="blogitems__container">
        <button class="create__button" @click="createPost">
          <h3>Новая работа</h3>
        </button>

        <PostItem v-for="post in posts" :key="post._id" :post="post" @postUpdated="refreshPosts" />
      </div>
    </div>
  </div>
</template>

<script>
import {mapGetters, mapActions} from 'vuex';
import PostItem from '@/components/Admin/PostItem.vue';
import AppService from "@/store/services"; // Импортируйте AppService

export default {
  components: {
    PostItem,
  },
  computed: {
    ...mapGetters(['getPosts']),
    posts() {
      return this.getPosts?.posts;
    },
  },

  created() {
    this.fetchPosts().then(() => {
      console.log('Загруженные посты:', this.posts);
    });
  },

  methods: {
    ...mapActions(['fetchPosts', 'changeOrder']),
    async createPost() {
      const newPost = {
        title: 'Введите заголовок',
        subtitle: 'Введите подзаголовок',
        main_img: '',
        date: new Date().toLocaleDateString('ru-RU'),
        blocks: []
      };

      try {
        const response = await AppService.addPost(newPost);
        console.log('Созданный пост:', response);
        this.$router.push(`/admin/edit-page/${response._id}`);
      } catch (error) {
        console.error(error);
      }
    },
    async refreshPosts() {
      await this.fetchPosts();
    },


  },
};
</script>

<style scoped lang="scss">
.admin {
  display: flex;
  flex-direction:  row;
  margin-top: 120px;

  &__nav {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 40px;
    min-width: 200px;
  }
}

.nav__item {
  font-size: 18px;
  cursor: pointer;
}

.blogitems {
  width: 100%;
  flex-grow: 1;
}

.blogitems__container {
  display: flex;
  flex-direction: column;
  align-items: stretch;
  gap: 20px;
  padding: 20px;
}

.create__button {
  border: none;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 20px;
  padding: 20px;
  width: 260px;
  font-size: 18px;
  background: rgba(240,240,240, 40%);
  border-radius: 10px ;
  cursor: pointer;
  height: 120px;
  transition: .2s ease;

  &:hover {
    background: rgba(240,240,240, 100%);
  }
}
</style>