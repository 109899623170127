<script>
export default {
  props: {
    name: String,
    image: String
  }
}
</script>

<template>
  <div class="homeWork">
    <img :src="image" alt="" class="cover">
    <div class="icon">
      <svg xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" viewBox="0 0 40 40">
        <path d="M 11.667 11.667 L 28.333 28.333 M 28.333 28.333 L 28.333 11.667 M 28.333 28.333 L 11.667 28.333" fill="#fff" stroke-width="2" stroke="rgb(255,255,255)" stroke-linecap="round" stroke-linejoin="round"/>
      </svg>
    </div>
    <div class="hover">
      {{ name }}
    </div>
  </div>
</template>

<style scoped lang="scss">
.homeWork {
 width: 100%;
  height: 400px;
  overflow: hidden;
  position: relative;
  cursor: pointer;

  .cover {
    object-fit: cover;
    width: 100%;
    height: 100%;
  }

  .icon {
    position: absolute;
    top: 20px;
    left: 20px;
    z-index: 2;

    transition: .3s ease;

    svg {
      width: 40px;
    }
  }

  .hover {
    position: absolute;
    z-index: 1;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    padding: 32px;
    background: rgba(#171717, .2);
    opacity: 0;

    transition: .3s ease;

    font-weight: 500;
    color: #fff;
    font-size: 14px;
    display: flex;
    align-items: flex-end;
  }

  &:hover {
    .hover {
      opacity: 1;
    }

    .icon {
      top: 40px;
      left: 40px;
    }
  }

  @media screen and (max-width: 809px)  {
    .hover {
      opacity: 1;
    }
  }
}
</style>