<script>
import { Splide, SplideSlide } from '@splidejs/vue-splide';
import '@splidejs/vue-splide/css/core';
export default {
  data () {
    return {

    }
  },
  components: {
    Splide,
    SplideSlide,
  },

}
</script>

<template>
  <Splide :options="{
          type: 'loop',
          drag: 'free',
          autoWidth: true,
          gap: '40px',
          arrows: false,
          pagination: false,
          autoplay: true,
          interval: 1,
          pauseOnHover: true,
          speed: 40000,
          direction: 'rtl',
          easing: 'linear',
          breakpoints: {
            1024: {
              gap: '100px',
            }
          }
        }" aria-label="My Favorite Images" class="logoslider">
    <SplideSlide>
      <img src="@/assets/Static_img/logo_Bacaldi.svg" alt="">
    </SplideSlide>
    <SplideSlide>
      <img src="@/assets/Static_img/logo_Beluga.svg" alt="">
    </SplideSlide>
    <SplideSlide>
      <img src="@/assets/Static_img/logo_BUD.svg" alt="">
    </SplideSlide>
    <SplideSlide>
      <img src="@/assets/Static_img/logo_Hunting.svg" alt="">
    </SplideSlide>
    <SplideSlide>
      <img src="@/assets/Static_img/logo_Jagermeister.svg" alt="">
    </SplideSlide>
    <SplideSlide>
      <img src="@/assets/Static_img/logo_JTI.svg" alt="">
    </SplideSlide>
    <SplideSlide>
      <img src="@/assets/Static_img/logo_JuliusMeinel.svg" alt="">
    </SplideSlide>
    <SplideSlide>
      <img src="@/assets/Static_img/logo_Miller.svg" alt="">
    </SplideSlide>
    <SplideSlide>
      <img src="@/assets/Static_img/logo_Spaten.svg" alt="">
    </SplideSlide>
    <SplideSlide>
      <img src="@/assets/Static_img/logo_Stellaartois.svg" alt="">
    </SplideSlide>
  </Splide>
</template>

<style scoped lang="scss">
.logoslider {
  mask: linear-gradient(90deg, transparent, white 20%, white 80%, transparent);
  padding: 60px 0;
}

.scroller {
  max-width: 100%;
  overflow: hidden;

  &__inner {
    padding-block: 32px;
    display: flex;
    gap: 72px;
    list-style: none;

    img {
      max-width: 80px;
    }
  }

  &[data-animated="true"] {
    overflow: hidden;
    mask: linear-gradient(90deg, transparent, white 20%, white 80%, transparent);

    .scroller__inner {
      width: max-content;
      flex-wrap: nowrap;
      animation: scroll 39s  reverse linear infinite;
    }
  }
}

.scroller[data-direction="right"] {
  --_animation-direction: reverse;
}

.scroller[data-direction="left"] {
  --_animation-direction: forwards;
}

.scroller[data-speed="fast"] {
  --_animation-duration: 22s;
}

.scroller[data-speed="slow"] {
  --_animation-duration: 60s;
}

@keyframes scroll {
  to {
    transform: translate(calc(-50% - 0.5rem));
  }
}

img {
  width: 70px;
}

.splide__list {
  transition-timing-function: linear !important;
}

/* general styles */

:root {
  --clr-neutral-100: hsl(0, 0%, 100%);
  --clr-primary-100: hsl(205, 15%, 58%);
  --clr-primary-400: hsl(215, 25%, 27%);
  --clr-primary-800: hsl(217, 33%, 17%);
  --clr-primary-900: hsl(218, 33%, 9%);
}

@media screen and (max-width: 809px) {
  .scroller__inner {
    img {
      max-width: 100px;
    }
  }

  .logoslider {
    padding: 40px 0;
  }

  .splide__slide {
    img {
      max-width: 50px;
    }
  }
}
</style>