<script>
import HomeWork from '@/components/HomePage/HomeWork.vue';
import ContactForm from '@/components/Forms/ContactForm.vue';
import LogoScroller from "@/components/LogoScroller.vue";
import {mapGetters, mapActions} from 'vuex';
export default {
  components: {
    HomeWork,
    ContactForm,
    LogoScroller
  },
  computed: {
    ...mapGetters(['getPosts']),
    posts() {
      return this.getPosts?.posts;
    },
  },

  created() {
    this.fetchPosts().then(() => {
      console.log('Загруженные посты:', this.posts);
      this.articles = this.posts.reverse(); // Используйте 'data' вместо 'fetched'
      console.log(this.articles)
    });
  },

  data() {
    return {
      articles: [],
      gridArticles: [],
      mainArticle: {}
    }
  },
  beforeMount() {

  },
  methods: {
    ...mapActions(['fetchPosts']),
    loadMore(e) {
      let i = this.gridArticles.length + 1;
      let j;


      if (this.articles.length - i < 10) {
        j = this.gridArticles.length + this.articles.length - i;
        this.$refs.moreBtn.classList.add('hidden');
      } else {
        j = i + 9;
      }

      for (i; i <= j; i++) {
        this.gridArticles.push(this.articles[i]);
      }
    },
  }
}
</script>

<template>
  <div class="page container">
    <div class="worksText">
      <h6>
        selected works
      </h6>
      <h3>
        We would like to share with you a few projects that we love to do. You can review the projects we have worked on and ask questions about all projects!
      </h3>
    </div>

    <div class="worksGrid">
      <template v-for="article in articles">
        <router-link v-if="article.isActive" :to="{ name: 'Work', params: { id: article._id }}" :id="article._id">
          <home-work
              :name="article.title"
              :image="article.main_img"
          >
          </home-work>
        </router-link>
      </template>

    </div>

    <logo-scroller></logo-scroller>

    <contact-form></contact-form>
  </div>
</template>

<style scoped lang="scss">
.page {
  padding-top: 200px;
}

.worksText {
  margin-bottom: 40px;
  text-align: center;
  h6 {
    margin-bottom: 18px;
  }
}

.worksGrid {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  gap: 32px;
  margin-bottom: 60px;
}

@media screen and (max-width: 1399px) {

}

@media screen and (max-width: 999px) {
  .worksGrid {
    grid-template-columns: repeat(1, 1fr);
  }
}

@media screen and (max-width: 809px) {
  .worksGrid {
    gap: 20px;
  }

  .page {
    padding-top: 170px;
  }
}
</style>