import AppService from '../store/services.js'

const postModule = {
    state: {
        posts: [],
    },
    mutations: {
        setPosts(state, posts) {
            state.posts = posts;
        },
        addPost(state, post) {
            state.posts.push(post);
        },
        deletePost(state, postId) {
            state.posts = state.posts.filter(post => post.id !== postId);
        },
    },
    actions: {
        async fetchPosts({ commit }) {
            try {
                const posts = await AppService.getPosts();
                commit('setPosts', posts);
            } catch (error) {
                console.error('Ошибка при загрузке постов', error);
            }
        },
        async createPost({ commit }, postData) {
            try {
                const createdPost = await AppService.addPost(postData);
                commit('addPost', createdPost);
            } catch (error) {
                console.error('Ошибка при создании поста', error);
            }
        },
        async deletePost({ commit }, postId) {
            try {
                const response = await AppService.deletePost(postId);
                if (response.ok) {
                    commit('deletePost', postId);
                } else {
                    console.error('Ошибка при удалении поста');
                }
            } catch (error) {
                console.error('Произошла ошибка:', error);
            }
        },

        async changeOrder({ commit }, { postId, direction }) {
            try {
                await AppService.changeIndex(postId, direction).then( async ()  => {
                    await AppService.getPosts().then(posts => {
                        commit('setPosts', posts);
                    });
                });
            } catch (error) {
                console.error('Произошла ошибка:', error);
            }
        },
        async toggleField ({ commit }, postId, key) {
            try {
                await AppService.toggleFieldValue(postId, key).then( async ()  => {
                    await AppService.getPosts().then(posts => {
                        commit('setPosts', posts);
                    });
                });
            } catch (error) {
                console.error('Произошла ошибка:', error);
            }
        }
    },
    getters: {
        getPosts: state => state.posts,
    },
};

export default postModule;