<script>
export default {
  props: {
    icon: String,
    title: String,
    description: String,
    tags: Array
  }
}
</script>

<template>
<div class="advantage">
  <div class="advantage-icon">
    <img :src="icon" alt="">
  </div>
  <div class="advantage-body">
    <div class="advantage-title">{{title}}</div>
    <div class="advantage-description">{{description}}</div>
    <div class="advantage-tags">
      <div class="tag" v-for="tag in tags">{{tag}}</div>
    </div>
  </div>
</div>
</template>

<style scoped lang="scss">
  .advantage {
    width: 100%;
    display: flex;
    border-radius: 12px;
    padding: 12px;
    border: 1px solid rgba(23, 23, 23, .12);
    background: #fff;
    gap: 16px;

    &-icon {
      width: 88px;
      height: 88px;
      overflow: hidden;

      img {
        width: 100%;
      }
    }

    &-body {
      flex: 1;
      display: flex;
      flex-direction: column;
    }

    &-title {
      font-weight: 600;
      letter-spacing: 0.04em;
      margin-bottom: 2px;
    }

    &-description {
      font-size: 14px;
      line-height: 1.5;
    }

    &-tags {
      flex: 1;
      display: flex;
      align-items: flex-end;
      gap: 8px;

      .tag {
        border-radius: 20px;
        border: 1px solid rgba(23, 23, 23, .12);
        padding: 4px 8px;
        font-size: 12px;
        line-height: 1.5;
        color: rgb(126, 128, 134);
      }
    }
  }

  @media screen and (max-width: 809px) {
    .advantage-title {
      font-size: 14px;
    }
    .advantage-description {
      font-size: 12px;
    }
    .advantage-tags {
      margin-top: 4px;
      flex-wrap: wrap;
      gap: 4px;
      .tag {
        font-size: 8px;
        padding: 4px 8px;
        white-space: nowrap;
      }
    }
  }
</style>