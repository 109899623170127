<script>
import ContactForm from '@/components/Forms/ContactForm.vue'
export default {
  components: {
    ContactForm
  }
}
</script>

<template>
  <div class="form">
    <h6>
      contact us
    </h6>
    <p>We are looking forward for creative collaborations, new acquaintances and inspiring projects, just contact us in a convenient way.</p>
    <form>
      <div class="form-top">
        <input type="text" placeholder="Name">
        <input type="text" placeholder="Email">
      </div>
      <textarea placeholder="Message"></textarea>
      <button>
        Send
      </button>
    </form>
  </div>
</template>

<style scoped lang="scss">
.form {
  max-width: 960px;
  margin: 60px auto;

  h6, p {
    text-align: center;
  }

  p {
    font-size: 24px;
    line-height: 1.3;
    margin: 18px 0 32px;
  }

  form {
    display: flex;
    flex-direction: column;
    align-items: stretch;
    gap: 15px;
  }

  .form-top {
    display: flex;
    gap: 15px;
  }

  input, textarea, button {
    line-height: 1.4;
    outline: none;
    border: none;
    padding: 15px;
    border-radius: 12px;
    font-size: 16px;
    background: rgb(245, 245, 245);
    color: #171717;
    resize: none;
  }

  input {
    flex: 1;
    min-width: 100px;
  }

  textarea {
    height: 150px;

    textarea::placeholder {
      color: #0bf;
    }
  }

  button {
    background: #171717;
    color: #fff;
    transition: .2s ease;
    text-align: center;
    font-weight: 600;
    cursor: pointer;

    &:hover {
      opacity: .9;
    }
  }
}

@media screen and (max-width: 809px){
  .form p {
    font-size: 20px;
  }
}
</style>