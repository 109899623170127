import {  About, Contact, Jobs, Works, Admin, Login, EditPage, WorkPage } from "../pages";
import { createRouter, createWebHistory } from 'vue-router'
import store from '../store';

import Home from '@/pages/Home.vue'

const routes = [
  {
    path: '/',
    name: 'home',
    component: Home
  },
  {
    path: '/about',
    name: 'about',
    component: About
  },
  {
    path: '/contacts',
    name: 'contacts',
    component: Contact
  },
  {
    path: '/jobs',
    name: 'Jobs',
    component: Jobs
  },
  {
    path: '/works',
    name: 'Works',
    component: Works
  },
  {
    path: '/admin',
    component: Admin,
    meta: { requiresAuth: true }
  },
  {
    path: '/login',
    component: Login
  },
  {
    path: '/admin/edit-page/:id',
    name: 'EditPage',
    component: EditPage,
    props: true,
    meta: { requiresAuth: true }
  },
  {
    path:  '/works/:id',
    name: 'Work',
    component:  WorkPage,
    props: true
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  scrollBehavior(to, from, savedPosition) {
    // always scroll to top
    return { top: 0 }
  },
  routes
})

router.beforeEach((to, from, next) => {
  if (to.matched.some(record => record.meta.requiresAuth)) {
    if (!store.state.auth.isAuth) {
      next({
        path: '/login',
        query: { redirect: to.fullPath }
      });
    } else {
      next();
    }
  } else {
    next();
  }
});

export default router
