import {createStore} from "vuex";
import createPersistedState from 'vuex-persistedstate';
import authModule from "../slicers/AuthSlice";
import postModule from "../slicers/PostSlice";

export default createStore({
  state: {
    menuOpen: false,
  },
  mutations: {
    menuBtnClick(state) {
      state.menuOpen = !state.menuOpen;
    },
  },
  plugins: [createPersistedState({
    key: 'your-app-key',
    paths: ['menuOpen', 'auth.isAuth', 'auth.userInfo'],
  })],
  modules: {
    auth: authModule,
    post: postModule
  },
});