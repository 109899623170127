import Vue from 'vue';
import Vuex from 'vuex';
import createPersistedState from 'vuex-persistedstate';
import AppService from "../store/services.js";

const state = {
    isAuth: false,
    userInfo: {},
};

const mutations = {
    setAuthStatus(state, isAuth) {
        state.isAuth = isAuth;
    },
    setUserInfo(state, userInfo) {
        state.userInfo = userInfo;
    },
};

const actions = {
    async login({ commit }, { email, password }) {
        try {
            const responseData = await AppService.postLogin(email, password);
            console.log(responseData.isAdmin);
            if (responseData.isAdmin === true) {
                commit("setAuthStatus", true);
                commit("setUserInfo", responseData);
            } else {
                commit("setAuthStatus", false);
                commit("setUserInfo", null);
            }

        } catch (error) {
            commit("setAuthStatus", false);
            commit("setUserInfo", null);
        }
    },
};

export default {
    state,
    mutations,
    actions,
};