
class ApplicationService {
    postLogin = async (email, password) => {
        const data = {
            email: email,
            password: password,
        };

        try {
            const response = await fetch('/api/users/auth', {
                method: "POST",
                credentials: "include",
                headers: { "content-type": "application/json" },
                body: JSON.stringify(data),
            });
            return await response.json();
        } catch (error) {
            console.error(error);
        }
    };

    getPosts = async () => {
        try {
            const response = await fetch('/api/posts', {
                method: "GET",
                headers: { "content-type": "application/json" },
            });
            return await response.json();
        } catch (error) {
            console.error(error);
        }
    };

    getPostById = async (postId) => {
        try {
             const response = await fetch(`/api/posts/${postId}`, {
                method: "GET",
                headers: { "content-type": "application/json" },
             });
            return await response.json();
        }   catch (error) {
            console.error(error);
        }
    }

    editPost = async (postId, postData) => {
        try {
            const response = await fetch(`/api/posts/${postId}`, {
                method: "PUT",
                headers: { "content-type": "application/json" },
                body: JSON.stringify(postData),
            });
            return await response.json();
        } catch (error) {
            console.error(error);
        }
    }

    deletePost = async (postId) => {
        try {
            const response = await fetch(`/api/posts/${postId}`, {
                method: "DELETE",
                headers: { "content-type": "application/json" },
            });
            return await response.json();
        } catch (error) {
            console.error(error);
        }
    }

    addPost = async (postData) => {
        try {
            const response = await fetch('/api/posts', {
                method: "POST",
                headers: { "content-type": "application/json" },
                body: JSON.stringify(postData),
            });
            return await response.json();
        } catch (error) {
            console.error(error);
        }
    };

    changeIndex = async (postId, direction) => {
        try {
            await fetch(`/api/posts/change-order`, {
                method: "PATCH",
                headers: { "content-type": "application/json" },
                body: JSON.stringify({ postId, direction }),
            });
        } catch (error) {
            console.error(error);
        }

    };
    changeBlockOrder = async (id, direction) => {
        try {
            await fetch(`/api/posts/update-block-order/${id}`, {
                method: "PATCH",
                headers: { "content-type": "application/json" },
                body: JSON.stringify({ direction }),
            });
        } catch (error) {
            console.error(error);
        }
    };

    uploadImage = async (fileUri, fileType, fileName) => {
        const formData = new FormData();
        formData.append("file", {
            uri: fileUri,
            type: `image/${fileType}`,
            name: `image.${fileType}`,
        });

        try {
            const response = await fetch('https://fs.5dev.kz/upload', {
                method: "POST",
                body: formData,
            });
            return await response.text();
        } catch (error) {
            console.error(error);
        }
    };

    toggleFieldValue = async (postId, key) => {
        console.log(postId, key);
        try {
            await fetch(`/api/posts/${postId}/toggle`, {
                method: "PATCH",
                headers: { "content-type": "application/json" },
                body: JSON.stringify({  key }),
            });
        } catch (error) {
            console.error(error);
        }

    }
}



const AppService = new ApplicationService();

const postData = {
    main_image: '',
    title: '',
    date: '',
    tag_name: '',
    blocks: [
        {
            type: '',
            text: '',
            sub_title: '',
            annotation: {
                text: '',
                img: ''
            }
        }
    ]
};

export default AppService;
