<script>
import Advantage from '@/components/About/Advantage.vue'
export default {
  components: {
    Advantage
  }
}
</script>

<template>
  <div class="page container">
    <div class="jobsText">
      <h6>JOBS</h6>
      <h3>Creatives have a multitude of career opportunities to showcase their talents and imaginative thinking across diverse industries, from branding and design to product to the dynamic world of design and artistic expression.</h3>
    </div>

    <div class="vacancies">
      <advantage
          :icon="'/img/advantage-icon.webp'"
          :title="'Strategy of communications'"
          :description="'Everything we design is communication'"
          :tags="['Creative Direction', 'Campaign Concepts', 'Brand Activation', 'Brand Strategy']"></advantage>
      <advantage
          :icon="'/img/advantage-icon.webp'"
          :title="'Strategy of communications'"
          :description="'Everything we design is communication'"
          :tags="['Creative Direction', 'Campaign Concepts', 'Brand Activation', 'Brand Strategy']"></advantage>
      <advantage
          :icon="'/img/advantage-icon.webp'"
          :title="'Strategy of communications'"
          :description="'Everything we design is communication'"
          :tags="['Creative Direction', 'Campaign Concepts', 'Brand Activation', 'Brand Strategy']"></advantage>
      <advantage
          :icon="'/img/advantage-icon.webp'"
          :title="'Strategy of communications'"
          :description="'Everything we design is communication'"
          :tags="['Creative Direction', 'Campaign Concepts', 'Brand Activation', 'Brand Strategy']"></advantage>
      <advantage
          :icon="'/img/advantage-icon.webp'"
          :title="'Strategy of communications'"
          :description="'Everything we design is communication'"
          :tags="['Creative Direction', 'Campaign Concepts', 'Brand Activation', 'Brand Strategy']"></advantage>
      <advantage
          :icon="'/img/advantage-icon.webp'"
          :title="'Strategy of communications'"
          :description="'Everything we design is communication'"
          :tags="['Creative Direction', 'Campaign Concepts', 'Brand Activation', 'Brand Strategy']"></advantage>
    </div>

    <div class="jobsText jobsText-2">
      <h6>JOIN US</h6>
      <h5>We are always looking for exceptional talents and brains to join our growing team. Send us your CV and portfolio.</h5>
      <a href="mailto:go@oneonone.team">go@oneonone.team</a>
    </div>
  </div>
</template>

<style scoped lang="scss">
.page {
  padding-top: 200px;
}

.jobsText {
  text-align: center;
  padding-top: 40px;

  h6 {
    margin-bottom: 18px;
  }

  &-2 {
    max-width: 875px;
    margin: 0 auto;
    padding-bottom: 80px;
  }

  a {
    text-decoration: underline;
    font-size: 24px;
  }
}

.vacancies {
  padding: 60px 0;
  display: grid;
  gap: 12px;
  grid-template-columns: repeat(2, 1fr);
}

@media screen and (max-width: 809px) {
  .vacancies {
    grid-template-columns: repeat(1, 1fr);
  }

  .jobsText {
    padding: 0;

    h3 {
      padding: 0 12px;
    }

    &-2 {
      padding: 20px 0 80px;
    }
  }

  .page {
    padding-top: 170px;
  }
}
</style>